import React from 'react'
import Header from '../common/Header'

function AboutPage() {
  return (
    <div>
     <Header />
      AboutPage
      </div>
  )
}

export default AboutPage